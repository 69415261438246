import "@babel/polyfill";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";

// import useSettings from "stores/settings";
import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useRouter } from "next/router";
// import { PageTokenCheck } from "function/PageTokenCheck";
import SEO from "components/SEO";

const Home = observer(({ headUrl, headTitle, headDesc }) => {
  // const settings = useSettings();
  const router = useRouter();

  // const IMP = window.IMP;
  // IMP.init("iamport");

  // 로그인 체크해서 url 이동
  useEffect(() => {
    // PageTokenCheck(settings, router);
    if (router && router.asPath === "/") {
      router.push("/user/market");
    } else {
      router.push(router.asPath);
    }
  }, []);

  // return <p>Loading...</p>;
  return (
    <div className="w-full flex justify-center items-center" style={{ width: "100%", minHeight: "100vh" }}>
      <SEO url={headUrl} title={headTitle} desc={headDesc} />
      <div className="mr-4">
        <div className="loader-demo-box2 mt-2">
          <div className="dot-opacity-loader">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <div>
        <div>잠시만 기다려주세요...</div>
      </div>
    </div>
  );
});

Home.getInitialProps = async () => {
  let headTitle = "인공지능 서비스 마켓 아이소";
  let headDesc = "AISO는 웹 기반의 다양한 AI 서비스를 쉽게 제작 판매하고 이용할 수 있는 인공지능플랫폼입니다.";
  const headUrl = `https://aiso.ai`;

  return { headUrl, headTitle, headDesc };
};

export default Home;
