import React from "react";
import Head from "next/head";

const SEO = ({ url, title, desc }) => {
  return (
    <Head>
      <title>{title ? title : "인공지능 서비스 마켓 아이소"}</title>
      <meta name="description" content={desc ? desc : "AISO는 웹 기반의 다양한 AI 서비스를 쉽게 제작 판매하고 이용할 수 있는 인공지능플랫폼입니다."} />
      <meta name="keywords" content="aiso, 인공지능, 인공지능 서비스, AI, machine learning" />
      <meta property="og:url" content={url ? url : "https://aiso.ai"} />
      <meta property="og:title" content={title ? title : "인공지능 서비스 마켓 아이소"} />
      <meta property="og:description" content={desc ? desc : "AISO는 웹 기반의 다양한 AI 서비스를 쉽게 제작 판매하고 이용할 수 있는 인공지능플랫폼입니다."} />
      <meta property="og:image" content="https://aiso.ai/images/header/aiso_logo.png" />
    </Head>
  );
};

export default SEO;
